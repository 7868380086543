import { defineStore } from 'pinia'
import { reactive, readonly } from 'vue'
import type { RouteHistoryState } from '@/modules/base/config/routesConfig'

/**
 * This storage will persist state for the back or reload navigation
 * @description 🔻 Don't use this store directly in components, use `useHistoryState` composable instead 🔻
 */
export const useHistoryStateStore = defineStore('historyState', () => {
  const historyState = reactive(
    new Map<RouteHistoryState, Record<string | number | symbol, unknown>>()
  )

  /**
   * Set a {@link historyState} for a route
   * @param route Preferably `Route`
   */
  const setHistoryState = (
    route: RouteHistoryState,
    value: Record<string, unknown>
  ) => {
    historyState.set(route, value)
  }

  /**
   * Set a {@link historyState} for a route affects only chosen key
   * @param route Preferably `Route`
   */
  const setHistoryStateByKey = (
    route: RouteHistoryState,
    key: string | number | symbol,
    value: unknown
  ) => {
    const oldHistoryState = historyState.get(route) ?? {}
    historyState.set(route, { ...oldHistoryState, [key]: value })
  }

  /**
   * Delete a {@link historyState} for a route
   * @param route Preferably `Route`
   */
  const deleteHistoryState = (route: RouteHistoryState) =>
    historyState.delete(route)

  /**
   * Init a {@link historyState} for a route
   * @param route Preferably `Route`
   * @description will only set {@link historyState} if there isn't any state for it yet
   */
  const initHistoryState = (
    route: RouteHistoryState,
    value: Record<string, unknown>
  ) => {
    if (!historyState.has(route)) {
      historyState.set(route, value)
    }
  }

  return {
    historyState: readonly(historyState),
    setHistoryState,
    setHistoryStateByKey,
    initHistoryState,
    deleteHistoryState,
  }
})
